<template>
  <div
    v-if="urgency > 0"
    class="rounded-full overflow-hidden absolute bottom-[6px] right-[6px]"
    :class="[
      compact ? 'text-3xs' : 'text-2xs',
      { 'text-sm': isDesktop }
    ]"
  >
    <div
      class="relative z-10 font-bold text-center px-1.5 py-0.5 rounded-full"
      :class="{
        'bg-white/80 text-slate-900': urgency === 0,
        'bg-yellow-300 text-slate-900': urgency === 1,
        'bg-red-600': urgency === 2,
        'bg-green-600': urgency === 3,
      }"
    >
      <span class="truncate" v-html="expiresInText"/>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useDeviceStore } from '@/store/device';

const props = defineProps({
  closeTime: {
    type: [String, Date,],
    default: null,
  },
  isClosed: {
    type: Boolean,
    default: false,
  },
  compact: {
    type: Boolean,
    default: false,
  },
  overtime: {
    type: Object,
    default: null,
  },
  comingSoon: {
    type: Boolean,
    default: false,
  },
  isDesktop: {
    type: Boolean,
    default: false,
  },
  closeMessage: {
    type: String,
    default: 'Drawing soon',
  },
});

const { $dayjs, } = useNuxtApp();

const uiStore = useUiStore();
const {
  secondsTicker,
} = storeToRefs(uiStore);

const deviceStore = useDeviceStore();
const {
  isMobileDevice,
} = storeToRefs(deviceStore);

const expiresInText = ref();
const urgency = ref(0);

// This whole thing needs SERIOUS rework, composables, clear requirements / conditions to present messages, correct messages etc
// This "method" is in a similar state in many areas of the application.
function setExpiresIn() {
  if (props.isClosed) {
    urgency.value = 2;
    expiresInText.value = props.overtime?.applicable
      ? isMobileDevice.value
        ? 'Overtime'
        : 'Overtime starting'
      : isMobileDevice.value
        ? 'Soon'
        : props.closeMessage;
    return;
  }

  const now = $dayjs();
  const endDate = $dayjs(props.closeTime);
  const expiresIn = endDate.diff(now, 's');

  if (expiresIn <= 0 && props.comingSoon) {
    urgency.value = 3;
    expiresInText.value = 'Launching...';
    return;
  } else if (expiresIn <= 0) {
    urgency.value = 2;
    const overtime = expiresInText.value = props.overtime?.applicable;
    expiresInText.value = overtime
      ? isMobileDevice.value
        ? 'Overtime'
        : 'Overtime starting'
      : isMobileDevice.value
        ? 'Soon'
        : props.closeMessage;
    return;
  }

  if (expiresIn > 3600) {
    urgency.value = 1;

    if (isMobileDevice.value) {
      expiresInText.value = `<span class="tabular-nums">${endDate.fromNow(true)}</span>`;
      return;
    }

    expiresInText.value = `<span class="tabular-nums">${endDate.fromNow(true)}</span> left`;
    return;
  }

  const totalMinutes = Math.floor(expiresIn / 60);
  const totalSeconds = expiresIn - (totalMinutes * 60);
  const result = `${totalMinutes ? totalMinutes + 'm' : ''} ${totalSeconds ? totalSeconds + 's' : ''}`;

  if (expiresIn <= 3600 && expiresIn > 1800) {
    urgency.value = 1;
  }

  if (expiresIn <= 1800) {
    urgency.value = 2;
  }

  if (!props.isClosed && props.closeTime && !props.comingSoon) {
    urgency.value = 3;
  }

  if (isMobileDevice.value && !props.isDesktop) {
    expiresInText.value = `<span class="tabular-nums">${result}</span>`;
    return;
  }

  if (props.comingSoon) {
    expiresInText.value = `Opens in <span class="tabular-nums">${result}</span>`;
    return;
  }

  expiresInText.value = `<span class="tabular-nums">${result}</span> left`;
}

watch(
  () => secondsTicker.value,
  () => {
    setExpiresIn();
  }
);

onMounted(() => {
  setExpiresIn();
});
</script>
